<template>
    <Modal v-model="showForm" title="新增商机" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="90">
            <FormItem label="客户名称" prop="customerId">
                <Select v-model="addForm.customerId" placeholder="请选择" @on-change="changeCustomer" @on-clear="clearCustomer" filterable clearable>
                    <Option v-for="item in customerList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="客户来源" v-if="addForm.sourceName !== null">{{ addForm.sourceName }}</FormItem>
            <FormItem label="联系人" prop="contactId">
                <Select v-model="addForm.contactId" placeholder="请选择" @on-change="changeContact" @on-clear="clearContact" filterable clearable>
                    <Option v-for="item in contactArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem v-if="addForm.phone != null" label="联系人电话">{{ addForm.phone }}</FormItem>
            <FormItem v-if="addForm.title != null" label="联系人职位">{{ addForm.title }}</FormItem>
            <FormItem label="商机类型" prop="type">
                <Select v-model="addForm.type" placeholder="请选择" filterable clearable>
                    <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="商机金额" prop="amount">
                <Input v-model="addForm.amount" placeholder="请输入" type="text"></Input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqAddOpportunity, reqGetContactListByCustomerId, reqGetCustomerPerUser} from "@/api/admin-api";
import {reqGetDictionaryListByCode} from "@/api/system-api";

export default {
    name: "addOpportunity",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            loading: false,
            showForm: this.value,
            contactArray: [],
            customerList: [],
            typeList: [],
            addForm: {
                customerId: null,
                contactId: null,
                type: null,
                amount: '',
            },
            addFormRule: {
                customerId: [
                    {required: true, message: '请选择客户名称', trigger: 'change'}
                ],
                contactId: [
                    {required: true, message: '请选择客户联系人', trigger: 'change'}
                ],
                type: [
                    {required: true, message: '请选择商机类型', type: 'integer', trigger: 'change'}
                ],
                amount: [
                    {required: true, message: "请输入金额", trigger: "blur"},
                    {pattern: /^(([1-9]{1}\d{0,7})|(0{1}))(\.\d{1,2})?$/, message: "请输入合法的金额数字，最多两位小数，最大金额99999999.99", trigger: "blur"}
                ],
            },
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            this.$refs['addForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                if (this.addForm.amount === '0') {
                    this.$Message.error("金额不能为0")
                    this.loading = false;
                    return false;
                }
                reqAddOpportunity(this.addForm).then((res)=>{
                    this.loading = false;
                    if (res.data.code === 1){
                        this.$Message.success('添加成功');
                        this.$emit('getOpportunityList');
                        this.closeModal(false);
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('添加失败');
                })
            });
        },
        getCustomerArray() {
            reqGetCustomerPerUser().then((res) => {
                this.customerList = res.data;
            }).catch(() => {
                this.customerList = []
            })
        },
        getTypeList() {
            reqGetDictionaryListByCode({code: 'opportunity_type'}).then((res) => {
                this.typeList = res.data.data
            }).catch(() => {
                this.typeList = []
            })
        },
        changeCustomer(val){
            let customer = this.customerList.find(item => item.id === val);
            if (customer){
                this.addForm.sourceName = customer.sourceName
            }
            reqGetContactListByCustomerId({id:val}).then((res)=>{
                if(res.data.code === 1){
                    this.contactArray = res.data.data
                }else {
                    this.contactArray = []
                }
            }).catch(()=>{
                this.contactArray = []
            })
        },
        changeContact(val){
            if (val){
                let contact = this.contactArray.find(item => item.id === val);
                if (contact){
                    this.addForm.phone = contact.phone
                    this.addForm.title = contact.title
                }
            }
        },
        clearCustomer(){
            this.addForm.contactId = null
            this.addForm.sourceName = null
            this.addForm.phone = null
            this.addForm.title = null
        },
        clearContact(){
            this.addForm.phone = null
            this.addForm.title = null
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
                this.addForm.phone = null
                this.addForm.title = null
                this.addForm.sourceName = null
                this.getCustomerArray()
                this.getTypeList()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {

    }
}
</script>
