<template>
    <Modal v-model="showForm" title="商机提审" width="680" :mask-closable="false">
        <Form ref="submitForm" :model="submitForm" :rules="submitFormRule" label-colon :label-width="90">
            <div class="divider">商机信息</div>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem class="plain-item" label="客户名称">{{ dataForm.customerName }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem class="plain-item" label="商机类型">{{ dataForm.typeName }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem class="plain-item" label="商机金额">{{ amount }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem class="plain-item" label="创建人">{{ dataForm.createByName }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="创建时间">{{ dataForm.createTime }}</FormItem>
                </Col>
            </Row>
            <div class="divider">提审进度<span class="tips">注：录入成本提交后，之前步骤不可编辑</span></div>
            <Tabs type="card" v-model="current" @on-click="submitContract" :animated="false">
                <TabPane label="1. 提交资质及合同" name="0">
                    <FormItem label="资质及合同" prop="contractFileArray" required>
                        <Upload ref="upload"
                                multiple
                                :disabled="(dataForm.opportunityApprovalStatus !== null && dataForm.opportunityApprovalStatus !== '驳回' && dataForm.opportunityApprovalStatus !== '提交' )|| dataForm.opportunityApprovalType === '录入业绩'"
                                :max-size="10 * 1024"
                                :action="uploadFiles"
                                :default-file-list="submitForm.contractFileArray"
                                :format="['jpg','png','JPEG','doc','docx','pdf']"
                                accept=".jpg,.png,.JPEG,.doc,.docx,.pdf"
                                :data="{type: 'private'}"
                                :before-upload="handleContractBeforeUpload"
                                :on-exceeded-size="handExceeded"
                                :on-remove="handleContractRemove"
                                :on-success="handleContractSuccess"
                                :on-preview="handlePreview"
                                :headers="header">
                            <Button icon="ios-cloud-upload-outline">上传</Button>
                        </Upload>
                        <span class="tips">注意：支持.doc, .docx, .pdf, .jpg, .jpeg, .png格式, 10M以内</span>
                    </FormItem>
                    <FormItem label="备注" prop="contractMemo">
                        <Input v-model="submitForm.contractMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}"
                               :disabled="(dataForm.opportunityApprovalStatus !== null && dataForm.opportunityApprovalStatus !== '驳回' && dataForm.opportunityApprovalStatus !== '提交' )|| dataForm.opportunityApprovalType === '录入业绩'"
                               show-word-limit type="textarea" placeholder="请输入" />
                    </FormItem>
                </TabPane>
                <TabPane label="2. 提交票据" name="1" :disabled="disableSubmitBills">
                    <FormItem label="是否开票" prop="needInvoice" required>
                        <RadioGroup v-model="submitForm.needInvoice" @on-change="changeNeedInvoice">
                            <Radio label="1"
                                   :disabled="(dataForm.opportunityApprovalStatus !== null && dataForm.opportunityApprovalStatus !== '驳回' && dataForm.opportunityApprovalStatus !== '提交' )|| dataForm.opportunityApprovalType === '录入业绩'">
                                <span>是</span>
                            </Radio>
                            <Radio label="0"
                                   :disabled="(dataForm.opportunityApprovalStatus !== null && dataForm.opportunityApprovalStatus !== '驳回' && dataForm.opportunityApprovalStatus !== '提交' )|| dataForm.opportunityApprovalType === '录入业绩'">
                                <span>否</span>
                            </Radio>
                        </RadioGroup>
                    </FormItem>
                    <FormItem v-if="submitForm.needInvoice === '1'" label="上传票据" prop="invoiceFileArray"
                              required>
                        <Upload ref="upload"
                                multiple
                                :disabled="(dataForm.opportunityApprovalStatus !== null && dataForm.opportunityApprovalStatus !== '驳回' && dataForm.opportunityApprovalStatus !== '提交' )|| dataForm.opportunityApprovalType === '录入业绩'"
                                :max-size="10 * 1024"
                                :action="uploadFiles"
                                :default-file-list="submitForm.invoiceFileArray"
                                :format="['jpg','png','JPEG','pdf']"
                                accept=".jpg,.png,.JPEG,.pdf"
                                :data="{type: 'private'}"
                                :before-upload="handleInvoiceBeforeUpload"
                                :on-exceeded-size="handExceeded"
                                :on-remove="handleInvoiceRemove"
                                :on-success="handleInvoiceSuccess"
                                :on-preview="handlePreview"
                                :headers="header">
                            <Button icon="ios-cloud-upload-outline">上传</Button>
                        </Upload>
                        <span class="tips">注意：支持.pdf, .jpg, .jpeg, .png格式, 10M以内</span>
                    </FormItem>
                    <FormItem label="备注" prop="invoiceMemo">
                        <Input v-model="submitForm.invoiceMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}"
                               :disabled="(dataForm.opportunityApprovalStatus !== null && dataForm.opportunityApprovalStatus !== '驳回' && dataForm.opportunityApprovalStatus !== '提交' )|| dataForm.opportunityApprovalType === '录入业绩'"
                               show-word-limit type="textarea" placeholder="请输入" />
                    </FormItem>
                </TabPane>
                <TabPane label="3. 确认回款" name="2" :disabled="disableConfirmPaymentCollection">
                    <FormItem label="回款金额" prop="receive" required>
                        <Input
                            :disabled="(dataForm.opportunityApprovalStatus !== null && dataForm.opportunityApprovalStatus !== '驳回' && dataForm.opportunityApprovalStatus !== '提交' )|| dataForm.opportunityApprovalType === '录入业绩'"
                            v-model="submitForm.receive"
                            placeholder="请输入" type="text"></Input>
                    </FormItem>
                    <FormItem label="证据截图" prop="affirmCollectionFileArray">
                        <Upload ref="upload"
                                multiple
                                :disabled="(dataForm.opportunityApprovalStatus !== null && dataForm.opportunityApprovalStatus !== '驳回' && dataForm.opportunityApprovalStatus !== '提交' )|| dataForm.opportunityApprovalType === '录入业绩'"
                                :max-size="10 * 1024"
                                :action="uploadFiles"
                                :default-file-list="submitForm.affirmCollectionFileArray"
                                :format="['jpg','png','JPEG']"
                                accept=".jpg,.png,.JPEG"
                                :data="{type: 'private'}"
                                :before-upload="handleCollectionBeforeUpload"
                                :on-exceeded-size="handExceeded"
                                :on-remove="handleCollectionRemove"
                                :on-success="handleCollectionSuccess"
                                :on-preview="handlePreview"
                                :headers="header">
                            <Button icon="ios-cloud-upload-outline">上传</Button>
                        </Upload>
                        <span class="tips">注意：支持.jpg, .jpeg, .png格式, 10M以内</span>
                    </FormItem>
                    <FormItem label="备注" prop="receiveMemo">
                        <Input v-model="submitForm.receiveMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}"
                               :disabled="(dataForm.opportunityApprovalStatus !== null && dataForm.opportunityApprovalStatus !== '驳回' && dataForm.opportunityApprovalStatus !== '提交' )|| dataForm.opportunityApprovalType === '录入业绩'"
                               show-word-limit type="textarea" placeholder="请输入" />
                    </FormItem>
                </TabPane>
                <TabPane label="4. 录入成本" name="3" :disabled="disablePrimeCosts">
                    <FormItem label="成本金额" prop="cost" required>
                        <Input :disabled="disableEnterPrimeCosts" v-model="submitForm.cost"
                               placeholder="请输入"
                               type="text"></Input>
                    </FormItem>
                    <FormItem label="证据截图" prop="enterCostFileArray">
                        <Upload ref="upload"
                                multiple
                                :disabled="disableEnterPrimeCosts"
                                :max-size="10 * 1024"
                                :action="uploadFiles"
                                :default-file-list="submitForm.enterCostFileArray"
                                :format="['jpg','png','JPEG']"
                                accept=".jpg,.png,.JPEG"
                                :data="{type: 'private'}"
                                :before-upload="handleCostBeforeUpload"
                                :on-exceeded-size="handExceeded"
                                :on-remove="handleCostRemove"
                                :on-success="handleCostSuccess"
                                :on-preview="handlePreview"
                                :headers="header">
                            <Button icon="ios-cloud-upload-outline">上传</Button>
                        </Upload>
                        <span class="tips">注意：支持.jpg, .jpeg, .png格式, 10M以内</span>
                    </FormItem>
                    <FormItem label="备注" prop="costMemo">
                        <Input v-model="submitForm.costMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" :disabled="disableEnterPrimeCosts"
                               show-word-limit type="textarea" placeholder="请输入" />
                    </FormItem>
                    <FormItem class="plain-item" label="审批进度">
                        <Timeline>
                            <TimelineItem v-for="item in enterCostScheduleList" :value="item.id" :key="item.id">
                                <div class="status">{{ item.status }}<span v-if="item.comment">{{ item.comment }}</span></div>
                                <div class="operator">{{ item.operatorName }} {{ item.updatedTime }}</div>
                            </TimelineItem>
                        </Timeline>
                    </FormItem>
                </TabPane>
                <TabPane label="5. 录入业绩" name="4" :disabled="disablePerformance">
                    <FormItem label="业绩类型" prop="performanceType" required>
                        <Select
                            :disabled="(dataForm.opportunityApprovalStatus === '待审批' || dataForm.opportunityApprovalStatus === '通过') && dataForm.opportunityApprovalType === '录入业绩'"
                            v-model="submitForm.performanceType" placeholder="请选择" filterable clearable>
                            <Option v-for="item in typeList" :value="item.id" :key="item.id">{{
                                    item.name
                                }}
                            </Option>
                        </Select>
                    </FormItem>
                    <FormItem label="业绩金额" prop="performance" required>
                        <Input
                            :disabled="(dataForm.opportunityApprovalStatus === '待审批' || dataForm.opportunityApprovalStatus === '通过') && dataForm.opportunityApprovalType === '录入业绩'"
                            v-model="submitForm.performance" placeholder="请输入" type="text"></Input>
                    </FormItem>
                    <FormItem label="完成时间" prop="completionTime" required>
                        <DatePicker
                            :disabled="(dataForm.opportunityApprovalStatus === '待审批' || dataForm.opportunityApprovalStatus === '通过') && dataForm.opportunityApprovalType === '录入业绩'"
                            v-model="submitForm.completionTime" type="date"></DatePicker>
                    </FormItem>
                    <FormItem label="备注" prop="performanceMemo">
                        <Input v-model="submitForm.performanceMemo" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}"
                               :disabled="(dataForm.opportunityApprovalStatus === '待审批' || dataForm.opportunityApprovalStatus === '通过') && dataForm.opportunityApprovalType === '录入业绩'"
                               show-word-limit type="textarea" placeholder="请输入" />
                    </FormItem>

                    <FormItem class="plain-item" label="审批进度">
                        <Timeline>
                            <TimelineItem v-for="item in enterPerformanceScheduleList" :value="item.id"
                                        :key="item.id">
                                <div class="status">{{ item.status }}<span v-if="item.comment">{{ item.comment }}</span ></div>
                                <div class="operator">{{ item.operatorName }} {{ item.updatedTime }}</div>
                            </TimelineItem>
                        </Timeline>
                    </FormItem>
                </TabPane>
            </Tabs>
        </Form>

        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary"
                    v-if="this.user === dataForm.createByUserName && (dataForm.opportunityApprovalStatus === '驳回' || dataForm.opportunityApprovalStatus === '提交' || dataForm.opportunityApprovalStatus === null) && (current === '0' || current === '1' || current === '2')"
                    :loading="loading" @click="handleSubmit">提交
            </Button>
            <Button type="primary"
                    v-if="this.user === dataForm.createByUserName && (current === '3') && !disableEnterPrimeCosts"
                    :loading="loading" @click="ReviewCost">提审
            </Button>
            <Button type="primary"
                    v-if="this.user === dataForm.createByUserName && (current === '4') && !((dataForm.opportunityApprovalStatus === '待审批' || dataForm.opportunityApprovalStatus === '通过') && dataForm.opportunityApprovalType === '录入业绩')"
                    :loading="loading" @click="reviewPerformance">提审
            </Button>
        </div>
    </Modal>
</template>
<script>

import {
    reqGetEnterCostScheduleById,
    reqGetEnterPerformanceScheduleById,
    reqGetOpportunityById,
    reqReviewCost,
    reqReviewPerformance,
    submitOpportunity
} from "@/api/admin-api";
import {downloadFile, reqGetDictionaryListByCode, uploadFileURL} from "@/api/system-api";
import {checkEditorContent} from "@/common/publicFuns";

export default {
    name: "submitOpportunity",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            amount: null,
            primeCost: 0,
            user: '',
            statusList: [
                {
                    id: '通过',
                    name: '通过',
                },
                {
                    id: '驳回',
                    name: '驳回',
                }
            ],
            costReasonShow: false,
            enterPerformanceScheduleList: [],
            enterCostScheduleList: [],
            disableSubmitBills: false,
            disablePerformance: false,
            disableEnterPrimeCosts: false,
            disablePrimeCosts: false,
            disableConfirmPaymentCollection: false,
            show: true,
            header: {},
            uploadFiles: uploadFileURL,
            uploadInvoice: false,
            current: '0',
            loading: false,
            showForm: this.value,
            customerList: [],
            typeList: [],
            dataForm: {},
            dataFormRule: {},
            submitForm: {
                contractFileArray: [],
                contractFiles: [],
                contractMemo: '',
                needInvoice: null,
                invoiceFileArray: [],
                invoiceFiles: [],
                invoiceMemo: '',
                receive: '',
                affirmCollectionFileArray: [],
                affirmCollectionFiles: [],
                receiveMemo: '',
                cost: '',
                enterCostFileArray: [],
                enterCostFiles: [],
                costMemo: '',
                completionTime: null,
                performanceType: null,
                performance: '',
                performanceMemo: '',
            },
            submitFormRule: {
                completionTime: [
                    {
                        message: '请选择日期', type: 'date', trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (value === null && this.dataForm.opportunityApprovalStatus === '通过' && this.dataForm.opportunityApprovalType === '录入成本') {
                                return callback(new Error("请选择日期"))
                            }
                            return callback();
                        }
                    }
                ],
                performanceType: [
                    {
                        message: '请选择类型', type: 'integer', trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (value === null && this.dataForm.opportunityApprovalStatus === '通过' && this.dataForm.opportunityApprovalType === '录入成本') {
                                return callback(new Error("请选择类型"))
                            }
                            return callback();
                        }
                    }
                ],
                performance: [
                    {
                        trigger: "blur", type: "number",
                        validator: (rule, value, callback) => {
                            if (value === '' && this.dataForm.opportunityApprovalStatus === '通过' && this.dataForm.opportunityApprovalType === '录入成本') {
                                return callback(new Error("请输入成本金额"));
                            } else if (value !== '' && this.dataForm.opportunityApprovalStatus === '通过' && this.dataForm.opportunityApprovalType === '录入成本') {
                                if ((value + '').length > 11) {
                                    return callback(new Error('最大金额99999999.99'))
                                } else if (value * 1 > 100000000) {
                                    return callback(new Error('最大金额99999999.99'))
                                } else if (value * 1 < 0) {
                                    return callback(new Error('最小金额不得小于0'))
                                }
                            }
                            return callback();
                        }
                    }
                ],
                performanceMemo: [
                    {
                        trigger: 'blur', type: "string",
                        validator: (rule, value, callback) => {
                            if (value && !checkEditorContent(value, 500)) {
                                return callback(new Error('业绩备注不能超过500字符'));
                            }
                            return callback();
                        }
                    }
                ],
                contractFileArray: [
                    {type: 'array', message: '请上传合同', trigger: 'change'}
                ],
                contractMemo: [
                    {
                        trigger: 'blur', type: "string",
                        validator: (rule, value, callback) => {
                            if (value && !checkEditorContent(value, 500)) {
                                return callback(new Error('合同备注不能超过500字符'));
                            }
                            return callback();
                        }
                    }
                ],
                invoiceFileArray: [
                    {
                        type: 'array', trigger: 'change',
                        validator: (rule, value, callback) => {
                            if (this.submitForm.needInvoice !== 'null' && this.submitForm.needInvoice === '1' && value.length === 0) {
                                return callback(new Error("请上传票据"))
                            }
                            return callback();
                        }
                    }
                ],
                invoiceMemo: [
                    {
                        trigger: 'blur', type: "string",
                        validator: (rule, value, callback) => {
                            if (value && !checkEditorContent(value, 500)) {
                                return callback(new Error('票据备注不能超过500字符'));
                            }
                            return callback();
                        }
                    }
                ],
                receive: [
                    {
                        trigger: "blur", type: "number",
                        validator: (rule, value, callback) => {
                            if (value !== '' && value !== null) {
                                let mobile = /^(([1-9]{1}\d{0,7})|(0{1}))(\.\d{1,2})?$/;
                                if (!mobile.test(value)) {
                                    return callback(new Error("请输入合法的金额数字，最多两位小数，最大金额99999999.99"));
                                }
                            } else if (this.primeCost === 1) {
                                return callback();
                            }
                            return callback();
                        }
                    }
                ],
                affirmCollectionFileArray: [
                    {type: 'array', message: '请上传证据截图', trigger: 'change'}
                ],
                receiveMemo: [
                    {
                        trigger: 'blur', type: "string",
                        validator: (rule, value, callback) => {
                            if (value && !checkEditorContent(value, 500)) {
                                return callback(new Error('回款备注不能超过500字符'));
                            }
                            return callback();
                        }
                    }
                ],
                cost: [
                    {
                        trigger: "blur", type: "number",
                        validator: (rule, value, callback) => {
                            if (value !== '' && value !== null) {
                                if ((value + '').length > 11) {
                                    return callback(new Error('最大金额99999999.99'))
                                } else if (value * 1 > 100000000) {
                                    return callback(new Error('最大金额99999999.99'))
                                } else if (value * 1 < 0) {
                                    return callback(new Error('最小金额不得小于0'))
                                }
                            } else if (this.current === '3') {
                                return callback(new Error("请输入成本金额"))
                            }
                            return callback();
                        }
                    }
                ],
                enterCostFileArray: [
                    {type: 'array', message: '请上传证据截图', trigger: 'change'}
                ],
                costMemo: [
                    {
                        trigger: 'blur', type: "string",
                        validator: (rule, value, callback) => {
                            if (value && !checkEditorContent(value, 500)) {
                                return callback(new Error('成本备注不能超过500字符'));
                            }
                            return callback();
                        }
                    }
                ],
            },
        }
    },
    methods: {
        reviewPerformance() {
            let that = this
            that.loading = true
            that.$refs['submitForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                that.dataForm.performanceType = that.submitForm.performanceType
                that.dataForm.performance = that.submitForm.performance
                that.dataForm.completionTime = that.submitForm.completionTime
                that.dataForm.performanceMemo = that.submitForm.performanceMemo
                let parmas = {
                    dataForm: that.dataForm,
                }
                reqReviewPerformance(parmas).then((res) => {
                    that.loading = false
                    if (res.data.code === 1) {
                        that.$Message.success('提审成功');
                        that.$emit('getOpportunityList');
                        that.closeModal(false);
                    } else {
                        that.$Message.error('提审失败');
                        that.closeModal(false);
                    }
                }).catch(() => {
                    that.$Message.error('提审失败');
                    that.closeModal(false);
                })
            })
        },
        ReviewCost() {
            let that = this
            that.loading = true
            that.$refs['submitForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                if (that.submitForm.receive === 0){
                    this.$Message.error("回款金额不能为0")
                    this.loading = false;
                    return false;
                }
                if (that.submitForm.cost === 0){
                    this.$Message.error("成本金额不能为0")
                    this.loading = false;
                    return false;
                }
                that.submitForm.contractFiles = []
                that.submitForm.invoiceFiles = []
                that.submitForm.affirmCollectionFiles = []
                that.submitForm.enterCostFiles = []
                that.submitForm.contractFileArray.forEach((item) => {
                    if (item.response !== undefined) {
                        that.submitForm.contractFiles.push({
                            name: item.response.data.fileName,
                            currentName: item.response.data.currentName
                        })
                    } else {
                        that.submitForm.contractFiles.push({name: item.name, currentName: item.currentName})
                    }
                })
                that.submitForm.invoiceFileArray.forEach((item) => {
                    if (item.response !== undefined) {
                        that.submitForm.invoiceFiles.push({
                            name: item.response.data.fileName,
                            currentName: item.response.data.currentName
                        })
                    } else {
                        that.submitForm.invoiceFiles.push({name: item.name, currentName: item.currentName})
                    }
                })
                that.submitForm.affirmCollectionFileArray.forEach((item) => {
                    if (item.response !== undefined) {
                        that.submitForm.affirmCollectionFiles.push({
                            name: item.response.data.fileName,
                            currentName: item.response.data.currentName
                        })
                    } else {
                        that.submitForm.affirmCollectionFiles.push({name: item.name, currentName: item.currentName})
                    }
                })
                that.submitForm.enterCostFileArray.forEach((item) => {
                    if (item.response !== undefined) {
                        this.submitForm.enterCostFiles.push({
                            name: item.response.data.fileName,
                            currentName: item.response.data.currentName
                        })
                    } else {
                        that.submitForm.enterCostFiles.push({name: item.name, currentName: item.currentName})
                    }
                })
                that.dataForm.needInvoice = that.submitForm.needInvoice
                that.dataForm.receive = that.submitForm.receive
                that.dataForm.contractMemo = that.submitForm.contractMemo
                that.dataForm.invoiceMemo = that.submitForm.invoiceMemo
                that.dataForm.receiveMemo = that.submitForm.receiveMemo
                that.dataForm.cost = that.submitForm.cost
                that.dataForm.costMemo = that.submitForm.costMemo
                let parmas = {
                    dataForm: that.dataForm,
                    form: {
                        contract: that.submitForm.contractFiles,
                        invoice: that.submitForm.invoiceFiles,
                        receive: that.submitForm.affirmCollectionFiles,
                        cost: that.submitForm.enterCostFiles,
                    }
                }
                reqReviewCost(parmas).then((res) => {
                    that.loading = false
                    if (res.data.code === 1) {
                        that.$Message.success('提审成功');
                        that.$emit('getOpportunityList');
                        that.closeModal(false);
                    } else {
                        that.$Message.error('提审失败');
                        that.closeModal(false);
                    }
                }).catch(() => {
                    that.$Message.error('提审失败');
                    that.closeModal(false);
                })
            })
        },
        handleSubmit() {
            let that = this
            that.loading = true
            that.$refs['submitForm'].validate((valid) => {
                if (!valid) {
                    that.loading = false;
                    return false;
                }
                if (that.submitForm.receive === '0'){
                    this.$Message.error("回款金额不能为0")
                    that.loading = false;
                    return false;
                }
                that.submitForm.contractFiles = []
                that.submitForm.invoiceFiles = []
                that.submitForm.affirmCollectionFiles = []
                that.submitForm.enterCostFiles = []
                that.submitForm.contractFileArray.forEach((item) => {
                    if (item.response !== undefined) {
                        that.submitForm.contractFiles.push({
                            name: item.response.data.fileName,
                            currentName: item.response.data.currentName
                        })
                    } else {
                        that.submitForm.contractFiles.push({name: item.name, currentName: item.currentName})
                    }
                })
                that.submitForm.invoiceFileArray.forEach((item) => {
                    if (item.response !== undefined) {
                        that.submitForm.invoiceFiles.push({
                            name: item.response.data.fileName,
                            currentName: item.response.data.currentName
                        })
                    } else {
                        that.submitForm.invoiceFiles.push({name: item.name, currentName: item.currentName})
                    }
                })

                that.submitForm.affirmCollectionFileArray.forEach((item) => {
                    if (item.response !== undefined) {
                        that.submitForm.affirmCollectionFiles.push({
                            name: item.response.data.fileName,
                            currentName: item.response.data.currentName
                        })
                    } else {
                        this.submitForm.affirmCollectionFiles.push({name: item.name, currentName: item.currentName})
                    }
                })
                that.dataForm.needInvoice = that.submitForm.needInvoice
                that.dataForm.receive = that.submitForm.receive
                that.dataForm.contractMemo = that.submitForm.contractMemo
                that.dataForm.invoiceMemo = that.submitForm.invoiceMemo
                that.dataForm.receiveMemo = that.submitForm.receiveMemo
                let parmas = {
                    dataForm: this.dataForm,
                    form: {
                        contract: that.submitForm.contractFiles,
                        invoice: that.submitForm.invoiceFiles,
                        receive: that.submitForm.affirmCollectionFiles,
                        cost: that.submitForm.enterCostFiles,
                    }
                }
                submitOpportunity(parmas).then((res) => {
                    that.loading = false
                    if (res.data.code === 1) {
                        that.$Message.success('提交成功');
                        that.$emit('getOpportunityList');
                        that.closeModal(false);
                    } else {
                        that.$Message.error('提交失败');
                        that.closeModal(false);
                    }
                }).catch(() => {
                    that.$Message.error('提交失败');
                    that.closeModal(false);
                })
            })
        },
        getOpportunityById() {
            reqGetOpportunityById({id: this.id}).then((res) => {
                this.dataForm = res.data.data;
                this.amount = this.dataForm.amount.toFixed(2);
                this.submitForm = res.data.data
                if (res.data.data.performance === null) {
                    this.submitForm.performance = ''
                }
                this.submitForm.needInvoice = res.data.data.needInvoice + ''
                let x = JSON.parse(res.data.data.resource)
                this.submitForm.contractFileArray = x.contract
                this.submitForm.invoiceFileArray = x.invoice
                this.submitForm.affirmCollectionFileArray = x.receive
                this.submitForm.enterCostFileArray = x.cost
                this.disablePaymentCollection()
                this.handleDisablePrimeCosts()
                this.handleDisableEnterPrimeCosts()
                this.handleDisablePerformance()
                this.disabledSubmitBills()
                if (this.dataForm.status === '洽谈中') {
                    this.submitContract('0')
                } else if (this.dataForm.status === '已签约') {
                    this.submitContract('1')
                } else if (this.dataForm.status === '已开票') {
                    this.submitContract('2')
                } else if (this.dataForm.opportunityApprovalStatus === '待审批' && this.dataForm.opportunityApprovalType === '录入成本') {
                    this.submitContract('3')
                } else if ((this.dataForm.opportunityApprovalStatus === '待审批' && this.dataForm.opportunityApprovalType === '录入业绩') || (this.dataForm.opportunityApprovalStatus === '通过' && this.dataForm.opportunityApprovalType === '录入成本' || this.dataForm.opportunityApprovalType === '录入业绩')) {
                    this.submitContract('4')
                } else if (this.dataForm.status === '已回款') {
                    this.submitContract('3')
                }
            }).catch(() => {
                this.dataForm = {}
            })
        },
        //上传文件大小
        handExceeded() {
            this.$Message.error('文件大小不得超过10M')
        },
        handleContractBeforeUpload(file) {
        },
        handleContractRemove(file, fileList) {
            this.submitForm.contractFileArray = [...fileList]
            this.disabledSubmitBills()
            this.disablePaymentCollection()
            this.handleDisablePrimeCosts()
        },
        //上传成功
        handleContractSuccess(res, file) {
            if (res.code === 1) {
                this.submitForm.contractFileArray.push(file)
                this.$Message.success('上传成功');
            } else {
                this.$Message.error('上传失败');
            }
            this.disabledSubmitBills()
            this.disablePaymentCollection()
            this.handleDisablePrimeCosts()
        },
        handleInvoiceBeforeUpload(file) {
        },
        handleInvoiceRemove(file, fileList) {
            this.submitForm.invoiceFileArray = [...fileList];
            this.handleDisablePrimeCosts()
            this.disablePaymentCollection()
        },
        //上传成功
        handleInvoiceSuccess(res, file) {
            if (res.code === 1) {
                this.submitForm.invoiceFileArray.push(file)
                this.$Message.success('上传成功');
            } else {
                this.$Message.error('上传失败');
            }
            this.handleDisablePrimeCosts()
            this.disablePaymentCollection()
        },
        handleCollectionBeforeUpload(file) {
        },
        handleCollectionRemove(file, fileList) {
            this.submitForm.affirmCollectionFileArray = [...fileList];
        },
        //上传成功
        handleCollectionSuccess(res, file) {
            if (res.code === 1) {
                this.submitForm.affirmCollectionFileArray.push(file)
                this.$Message.success('上传成功');
            } else {
                this.$Message.error('上传失败');
            }
            this.handleDisablePrimeCosts()
        },
        handleCostBeforeUpload(file) {

        },
        handleCostRemove(file, fileList) {
            this.submitForm.enterCostFileArray = [...fileList];
        },
        //上传成功
        handleCostSuccess(res, file) {
            if (res.code === 1) {
                this.submitForm.enterCostFileArray.push(file)
                this.$Message.success('上传成功');
            } else {
                this.$Message.error('上传失败');
            }
        },
        submitContract(name) {
            this.current = name
        },
        disabledSubmitBills() {
            if (this.submitForm.contractFileArray.length === 0) {
                this.disableSubmitBills = true
            } else {
                this.disableSubmitBills = false
            }
        },
        changeNeedInvoice() {
            this.disablePaymentCollection()
            this.handleDisablePrimeCosts()
        },
        disablePaymentCollection() {
            if (this.disableSubmitBills) {
                this.disableConfirmPaymentCollection = true
            } else if (this.submitForm.needInvoice === '0' || this.submitForm.invoiceFileArray.length > 0) {
                this.disableConfirmPaymentCollection = false
            } else if (this.dataForm.opportunityApprovalType === '录入业绩' || this.dataForm.opportunityApprovalType === '录入成本') {
                this.disableConfirmPaymentCollection = false
            } else {
                this.disableConfirmPaymentCollection = true
            }
        },
        handleDisablePrimeCosts() {
            if (this.submitForm.contractFileArray.length > 0 && this.dataForm.status === '已回款' && (this.submitForm.needInvoice === '0' || this.submitForm.invoiceFileArray.length > 0)) {
                this.disablePrimeCosts = false
            } else if (this.dataForm.opportunityApprovalType === '录入业绩') {
                this.disablePerformance = false
            } else {
                this.disablePrimeCosts = true
            }
        },
        handleDisableEnterPrimeCosts() {
            if (this.dataForm.opportunityApprovalType === '录入业绩') {
                this.disableEnterPrimeCosts = true
            } else if (this.dataForm.opportunityApprovalType === '录入成本' && this.dataForm.opportunityApprovalStatus === '通过') {
                this.disableEnterPrimeCosts = true
            } else if (this.dataForm.opportunityApprovalStatus !== '待审批') {
                this.disableEnterPrimeCosts = false
            } else {
                this.disableEnterPrimeCosts = true
            }
        },
        handleDisablePerformance() {
            if (this.dataForm.opportunityApprovalStatus === '通过' && this.dataForm.opportunityApprovalType === '录入成本') {
                this.disablePerformance = false
            } else if (this.dataForm.opportunityApprovalType === '录入业绩') {
                this.disablePerformance = false
            } else {
                this.disablePerformance = true
            }
        },
        getTypeList() {
            reqGetDictionaryListByCode({code: 'opportunity_type'}).then((res) => {
                this.typeList = res.data.data
            }).catch(() => {
                this.typeList = []
            })
        },
        getEnterCostScheduleById() {
            reqGetEnterCostScheduleById({id: this.id}).then((res) => {
                this.enterCostScheduleList = res.data.data
            }).catch(() => {
                this.enterCostScheduleList = []
            })
        },
        getEnterPerformanceScheduleById() {
            reqGetEnterPerformanceScheduleById({id: this.id}).then((res) => {
                this.enterPerformanceScheduleList = res.data.data
            }).catch(() => {
                this.enterCostScheduleList = []
            })
        },
        handlePreview(file) {
            let para = {}
            if (file.currentName !== undefined) {
                para = {
                    type: 'private',
                    fileName: file.currentName
                }
            } else {
                para = {
                    type: 'private',
                    fileName: file.response.data.currentName
                }
            }
            downloadFile(para).then((res) => {
                if (res.data.size > 0) {
                    var blob = new Blob([res.data], {
                        type: 'application/vnd.openxmlformats-  officedocument.spreadsheetml.sheet;charset=utf-8',
                    }) //type这里表示xlsx类型
                    var downloadElement = document.createElement('a')
                    var href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href
                    downloadElement.download = file.name//下载后文件名
                    document.body.appendChild(downloadElement)
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                } else {
                    this.$Message.error('下载失败')
                }
            }).catch(() => {
                this.$Message.error('下载失败')
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                if (this.$refs['submitForm'] !== undefined) {
                    this.$refs['submitForm'].resetFields();
                }
                let token = sessionStorage.getItem('Authorization')
                this.user = sessionStorage.getItem('un')
                this.header = {Authorization: token}
                this.submitForm.contractFileArray = []
                this.submitForm.invoiceFileArray = []
                this.submitForm.affirmCollectionFileArray = []
                this.submitForm.enterCostFileArray = []
                this.submitForm.contractFiles = []
                this.submitForm.invoiceFiles = []
                this.submitForm.affirmCollectionFiles = []
                this.submitForm.enterCostFiles = []
                this.submitForm.contractMemo = ''
                this.submitForm.needInvoice = 1
                this.submitForm.invoiceMemo = ''
                this.submitForm.receive = ''
                this.submitForm.receiveMemo = ''
                this.submitForm.cost = ''
                this.submitForm.costMemo = ''
                this.getOpportunityById()
                this.getTypeList()
                this.getEnterCostScheduleById()
                this.getEnterPerformanceScheduleById()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
