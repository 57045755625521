<template>
    <Modal v-model="showForm" title="商机详情" width="720" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" label-colon :label-width="90">
            <div class="divider">商机信息</div>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="客户名称">{{ viewForm.customerName }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="客户来源">{{ viewForm.sourceName }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="联系人">{{ viewForm.contactName }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="联系人电话">{{ viewForm.contactPhone }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="联系人职位">{{ viewForm.contactTitle }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="商机类型">{{ viewForm.typeName }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="商机金额">{{ amount }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="创建时间">{{ viewForm.createTime }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="创建人">{{ viewForm.createByName }}</FormItem>
                </Col>
            </Row>
            <div class="divider">业绩信息</div>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem class="multiline-content" label="合同附件">
                        <div v-for="item in contractImgData" >
                            <a @click="handleDownload(item)">{{ item.name }}</a>
                        </div>
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem class="multiline-content" label="发票附件">
                        <div v-for="item in invoiceImgData">
                            <a @click="handleDownload(item)">{{ item.name }}</a>
                        </div>
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem class="multiline-content" label="合同备注">
                        {{ viewForm.contractMemo }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem class="multiline-content" label="票据备注">
                        {{ viewForm.invoiceMemo }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="回款附件">
                        <div class="upload-image-list" v-for="item in receiveImgData" >
                            <img :src="item">
                            <div class="upload-image-list-cover">
                                <Icon type="ios-eye" @click.native="handlePreview(item)"></Icon>
                            </div>
                        </div>
                    </FormItem>
                </Col>
                <Col  span="12">
                    <FormItem label="成本附件">
                        <div class="upload-image-list" v-for="item in costImgData" >
                            <img :src="item">
                            <div class="upload-image-list-cover">
                                <Icon type="ios-eye" @click.native="handlePreview(item)"></Icon>
                            </div>
                        </div>
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem class="multiline-content" label="回款备注">
                        {{ viewForm.receiveMemo }}
                    </FormItem>
                </Col>
                <Col span="12">
                    <FormItem class="multiline-content" label="成本备注">
                        {{ viewForm.costMemo }}
                    </FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="回款金额">{{ viewForm.receive }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="成本金额">{{ viewForm.cost }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="业绩金额">{{ viewForm.performance }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="业绩类型">{{ viewForm.performanceTypeName }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="业绩备注">{{ viewForm.performanceMemo }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="审核人">{{ viewForm.opportunityApprovalName }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="完成时间">{{ viewForm.completionTime }}</FormItem>
                </Col>
            </Row>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="860">
            <img :src="imageUrl" style="width: 100%" />
        </Modal>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>

import {reqGetOpportunityById} from "@/api/admin-api";
import {downloadFile} from "@/api/system-api";

export default {
    name: "viewOpportunity",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            amount: null,
            imageUrl: '',
            showPreview: false,
            showForm: this.value,
            dataList: [],
            viewForm: {},
            contractImgData: [],
            invoiceImgData: [],
            costImgData: [],
            receiveImgData: [],
        }
    },
    methods: {
        getOpportunityById() {
            reqGetOpportunityById({id: this.id}).then((res) => {
                this.viewForm = res.data.data;
                this.amount = this.viewForm.amount.toFixed(2)
                let x = JSON.parse(res.data.data.resource)
                for (let i = 0; i < x.contract.length; i++) {
                    this.contractImgData.push({currentName: x.contract[i].currentName, name: x.contract[i].name})
                }
                for (let i = 0; i < x.invoice.length; i++) {
                    this.invoiceImgData.push({currentName: x.invoice[i].currentName, name: x.invoice[i].name})
                }
                for (let i = 0; i < x.receive.length; i++) {
                    this.showReceive(x.receive[i].currentName)
                }
                for (let i = 0; i < x.cost.length; i++) {
                    this.showCostImg(x.cost[i].currentName)
                }
            }).catch(() => {
                this.viewForm = {}
            })
        },
        //展示图片
        showReceive(val) {
            let para = {
                type: 'private',
                fileName: val
            }
            let that = this;
            downloadFile(para).then((res) => {
                let name = window.URL.createObjectURL(new Blob([res.data]))
                that.receiveImgData.push(name)
            }).catch(() => {
                that.receiveImgData = null;
            });
        },
        //展示图片
        showCostImg(val) {
            let para = {
                type: 'private',
                fileName: val
            }
            let that = this;
            downloadFile(para).then((res) => {
                let name = window.URL.createObjectURL(new Blob([res.data]))
                that.costImgData.push(name)
            }).catch(() => {
                that.costImgData = null;
            });
        },
        handlePreview(val) {
            this.imageUrl = val
            this.showPreview = true;
        },
        handleDownload(val){
            let para = {}
            if (val !== undefined) {
                para = {
                    type: 'private',
                    fileName: val.currentName
                }
            }
            downloadFile(para).then((res) => {
                if (res.data.size > 0) {
                    var blob = new Blob([res.data], {
                        type: 'application/vnd.openxmlformats-  officedocument.spreadsheetml.sheet;charset=utf-8',
                    }) //type这里表示xlsx类型
                    var downloadElement = document.createElement('a')
                    var href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href
                    downloadElement.download = val.name//下载后文件名
                    document.body.appendChild(downloadElement)
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                } else {
                    this.$Message.error('下载失败')
                }
            }).catch(() => {
                this.$Message.error('下载失败')
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.getOpportunityById()
            } else {// 反之则关闭页面
                this.invoiceImgData = [];
                this.contractImgData = [];
                this.costImgData = [];
                this.receiveImgData = [];
                this.closeModal(val);
            }
        }
    }
}
</script>
