<template>
    <Modal v-model="showForm" title="编辑商机" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <FormItem label="客户名称" prop="customerId">
                <Select v-model="editForm.customerId" placeholder="请选择" @on-change="changeCustomer" @on-clear="clearCustomer" filterable clearable>
                    <Option v-for="item in customerList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="客户来源" v-if="editForm.sourceName !== null">{{ editForm.sourceName }}</FormItem>
            <FormItem label="联系人" prop="contactId">
                <Select v-model="editForm.contactId" placeholder="请选择" @on-change="changeContact" @on-clear="clearContact" filterable clearable>
                    <Option v-for="item in contactArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem v-if="editForm.phone != null" label="联系人电话">{{ editForm.phone }}</FormItem>
            <FormItem v-if="editForm.title != null" label="联系人职位">{{ editForm.title }}</FormItem>
            <FormItem label="商机类型" prop="type">
                <Select v-model="editForm.type" placeholder="请选择" filterable clearable>
                    <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="商机金额" prop="amount">
                <Input v-model="editForm.amount" placeholder="请输入" type="text"></Input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>

import {
    reqEditOpportunity,
    reqGetContactListByCustomerId,
    reqGetCustomerPerUser,
    reqGetOpportunityById
} from "@/api/admin-api";
import {reqGetDictionaryListByCode} from "@/api/system-api";

export default {
    name: "editOpportunity",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },

    data() {
        return {
            loading: false,
            showForm: this.value,
            contactArray: [],
            customerList: [],
            typeList: [],
            editForm: {
                customerId: null,
                contactId: null,
                type: null,
                amount: '',
            },
            editFormRule: {
                customerId: [
                    {required: true, message: '请选择客户名称', trigger: 'change'}
                ],
                contactId: [
                    {required: true, message: '请选择客户联系人', trigger: 'change'}
                ],
                type: [
                    {required: true, message: '请选择商机类型', type: 'integer', trigger: 'change'}
                ],
                amount: [
                    {
                        required: true,
                        trigger: 'blur',
                        validator: (rule, value, callback) => {
                            if (value) {
                                let mobile = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
                                if (!mobile.test(value)) {
                                    return callback(new Error('最大保留小数点后两位'))
                                } else if (value * 1 > 100000000) {
                                    return callback(new Error('最大金额99999999.99'))
                                } else if (value * 1 < 0) {
                                    return callback(new Error('最小金额不得小于0'))
                                }
                            }
                            return callback()
                        }
                    }
                ],
            },
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            this.$refs['editForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                if (this.editForm.amount === '0') {
                    this.$Message.error("金额不能为0")
                    this.loading = false;
                    return false;
                }
                reqEditOpportunity(this.editForm).then((res)=>{
                    this.loading = false;
                    if (res.data.code === 1){
                        this.$Message.success('编辑成功');
                        this.$emit('getOpportunityList');
                        this.closeModal(false);
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('编辑失败');
                })
            });
        },
        getOpportunityById() {
            reqGetOpportunityById({id: this.id}).then((res)=>{
                this.editForm = res.data.data
                this.changeCustomer(res.data.data.customerId)
            }).catch(()=>{
                this.editForm = {}
            })
        },
        getCustomerArray() {
            reqGetCustomerPerUser().then((res) => {
                this.customerList = res.data;
                this.getOpportunityById()
            }).catch(() => {
                this.customerList = []
            })
        },
        getTypeList() {
            reqGetDictionaryListByCode({code: 'opportunity_type'}).then((res) => {
                this.typeList = res.data.data
            }).catch(() => {
                this.typeList = []
            })
        },
        changeCustomer(val){
            let customer = this.customerList.find(item => item.id === val);
            if (customer){
                this.editForm.sourceName = customer.sourceName
            }
            reqGetContactListByCustomerId({id:val}).then((res)=>{
                if(res.data.code === 1){
                    this.contactArray = res.data.data
                    this.changeContact(this.editForm.contactId)
                }else {
                    this.contactArray = []
                }
            }).catch(()=>{
                this.contactArray = []
            })
        },
        changeContact(val){
            if (val){
                let contact = this.contactArray.find(item => item.id === val);
                if (contact){
                    this.editForm.phone = contact.phone
                    this.editForm.title = contact.title
                }
            }
        },
        clearCustomer(){
            this.editForm.contactId = null
            this.editForm.sourceName = null
            this.editForm.phone = null
            this.editForm.title = null
        },
        clearContact(){
            this.editForm.phone = null
            this.editForm.title = null
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['editForm'].resetFields();
                this.editForm.phone = null
                this.editForm.title = null
                this.editForm.sourceName = null
                this.getTypeList()
                this.getCustomerArray()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
