<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>客户管理</BreadcrumbItem>
                <BreadcrumbItem>商机管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem class="ivu-form-item-xl">
                    <Input v-model="filter.keywords" placeholder="客户名称，联系人" clearable/>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.create" placeholder="请选择创建人" filterable clearable>
                        <Option v-for="item in createArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <DatePicker type="daterange" v-model="filter.goalDateRange" placeholder="请选择商机创建周期" separator=" 至 " clearable></DatePicker>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.type" placeholder="请选择类型" filterable clearable>
                        <Option v-for="item in typeList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.status" placeholder="请选择状态" filterable clearable>
                        <Option value="洽谈中">洽谈中</Option>
                        <Option value="已签约">已签约</Option>
                        <Option value="已开票">已开票</Option>
                        <Option value="已回款">已回款</Option>
                        <Option value="已结项">已结项</Option>
                        <Option value="需求暂停">需求暂停</Option>
                        <Option value="需求终止">需求终止</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="handleAdd" v-if="permissionButton.add">新增</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="action">
                        <Dropdown transfer>
                            <a href="javascript:void(0)">操作<Icon type="ios-arrow-down"></Icon></a>
                            <DropdownMenu class="table-dropdown" slot="list">
                                <DropdownItem v-if="permissionButton.view" @click.native="handleView(row, index)">详情</DropdownItem>
                                <DropdownItem v-if="permissionButton.edit && (row.status !== '需求终止' && row.status !== '需求暂停' && row.status !== '已结项' && row.opportunityApprovalStatus !== '待审批')  && subordinate.includes(row.userName)" @click.native="handleEdit(row, index)">编辑</DropdownItem>
                                <DropdownItem v-if="permissionButton.submit && (row.status !== '需求终止' && row.status !== '需求暂停' && row.status !== '已结项') && subordinate.includes(row.userName)" @click.native="handleSubmit(row, index)">提审</DropdownItem>
                                <DropdownItem v-if="permissionButton.suspend && (row.status === '洽谈中' || row.status === '已签约' || row.status === '已开票' || row.status === '已回款' || row.opportunityApprovalStatus === '提交') && row.opportunityApprovalStatus !== '待审批'  && subordinate.includes(row.userName)" @click.native="handleSuspend(row, index)">暂停</DropdownItem>
                                <DropdownItem v-if="permissionButton.suspend && (row.status === '需求暂停')  && subordinate.includes(row.userName)" @click.native="handleOpen(row, index)">开启</DropdownItem>
                                <DropdownItem v-if="permissionButton.termination && (row.status !== '需求终止' && row.status !== '已结项' && row.opportunityApprovalStatus !== '待审批') && subordinate.includes(row.userName)" @click.native="handleTermination(row, index)">终止</DropdownItem>
                                <DropdownItem class="table-delete" v-if="permissionButton.del && row.opportunityApprovalStatus !== '待审批' && subordinate.includes(row.userName)" @click.native="handleDel(row, index)">删除</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
        <add-opportunity v-model="showAddModal" v-on:getOpportunityList="getOpportunityList"></add-opportunity>
        <edit-opportunity  v-model="showEditModal" :id="id" v-on:getOpportunityList="getOpportunityList"></edit-opportunity>
        <submit-opportunity v-model="showSubmitModal" :id="id" v-on:getOpportunityList="getOpportunityList"></submit-opportunity>
        <view-opportunity v-model="showViewModal" :id="id"></view-opportunity>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {
    reqDelOppOpportunity,
    reqEditOpportunityStatus,
    reqGetOpportunity,
    reqGetSubordinateIds,
    reqOpenOpportunity
} from "@/api/admin-api";
import {reqGetDictionaryListByCode, reqGetUserList} from "@/api/system-api";
import addOpportunity from './add'
import editOpportunity from './edit'
import submitOpportunity from './submits'
import viewOpportunity from './view'

export default {
    name: 'resumeList',
    components: {
        addOpportunity,
        editOpportunity,
        submitOpportunity,
        viewOpportunity
    },
    data() {
        return {
            id: '',
            filter: {
                keywords: '',
                status: null,
                type: null,
                goalDateRange: null,
                create: null
            },
            subordinate: [],
            createArray: [],
            userName: null,
            showAddModal: false,
            showEditModal: false,
            showSubmitModal: false,
            showViewModal: false,
            typeList: [],
            loading: false,
            dataList: [],
            permissionButton: {
                add: false,
                edit: false,
                view: false,
                del: false,
                suspend: false,
                termination: false,
                submit: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '客户名称',
                    key: 'customerName',
                    tooltip: 'true',
                    minWidth: 300,
                },
                {
                    title: '联系人',
                    key: 'contactName',
                    width: 120,
                },
                {
                    title: '联系人电话',
                    key: 'contactPhone',
                    width: 130,
                },
                {
                    title: '联系地址',
                    key: 'address',
                    tooltip: 'ture',
                    minWidth: 300,
                },
                {
                    title: '类型',
                    key: 'typeName',
                    width: 80,
                },
                {
                    title: '金额',
                    width: 120,
                    render: (h, params) => {
                        return h('span', params.row.amount.toFixed(2))
                    }
                },
                {
                    title: '状态',
                    key: 'status',
                    width: 100,
                },
                {
                    title: '创建人',
                    key: 'createByName',
                    width: 120,
                },
                {
                    title: '更新时间',
                    key: 'updatedTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        getOpportunityList() {
            this.loading = true;
            this.dataList = [];

            let endTime =  ((this.filter.goalDateRange && this.filter.goalDateRange[1] !== '' && this.filter.goalDateRange.length > 1) ? this.$moment(this.filter.goalDateRange[1]).format('YYYY-MM-DD') : null);
            let startTime = ((this.filter.goalDateRange && this.filter.goalDateRange[0] !== '' && this.filter.goalDateRange.length > 0) ? this.$moment(this.filter.goalDateRange[0]).format('YYYY-MM-DD') : null);
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.filter.keywords,
                type: this.filter.type,
                status: this.filter.status,
                create: this.filter.create,
                startTime: startTime,
                endTime: endTime
            };
            reqGetOpportunity(para).then((res)=>{
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(()=>{
                this.loading = false;
                this.dataList = [];
            })
        },
        resetting() {
            this.loading = true;
            this.dataList = [];
            this.filter.keywords = '';
            this.filter.status = null;
            this.filter.type = null;
            this.filter.goalDateRange = null;
            this.filter.create = null;
            let endTime =  ((this.filter.goalDateRange && this.filter.goalDateRange[1] !== '' && this.filter.goalDateRange.length > 1) ? this.$moment(this.filter.goalDateRange[1]).format('YYYY-MM-DD') : null);
            let startTime = ((this.filter.goalDateRange && this.filter.goalDateRange[0] !== '' && this.filter.goalDateRange.length > 0) ? this.$moment(this.filter.goalDateRange[0]).format('YYYY-MM-DD') : null);
            let para = {
                pageNum: 1,
                pageSize: 20,
                keywords: this.filter.keywords,
                type: this.filter.type,
                status: this.filter.status,
                create: this.filter.create,
                startTime: startTime,
                endTime: endTime
            }
            reqGetOpportunity(para).then((res)=>{
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(()=>{
                this.loading = false;
                this.dataList = [];
            })
        },
        handleOpen(row, index) {
            reqOpenOpportunity({id: row.id}).then((res)=>{
                if (res.data.code === 1){
                    this.$Message.success('开启商机成功');
                }else {
                    this.$Message.error('开启商机失败');
                }
                this.getOpportunityList();
            }).catch(()=>{
                this.$Message.error('开启商机失败');
            })
        },
        handleAdd() {
            this.showAddModal = true
        },
        handleEdit(row, index) {
            this.id = row.id
            this.showEditModal = true
        },
        handleView(row, index) {
            this.id = row.id
            this.showViewModal = true
        },
        handleSubmit(row, index) {
            this.id = row.id
            this.showSubmitModal = true
        },
        handleSuspend(row, index) {
            reqEditOpportunityStatus({id: row.id, status: '需求暂停'}).then((res)=>{
                if (res.data.code === 1) {
                    this.$Message.success('暂停商机成功');
                } else {
                    this.$Message.error("暂停商机失败");
                }
                this.getOpportunityList();
            }).catch(()=>{
                this.$Message.error("暂停商机失败");
            })
        },
        handleTermination(row, index) {
            this.$Modal.confirm({
                title: '终止商机',
                content: '确定终止：'+ row.customerName +' 商机，此操作不可逆',
                width: '320',
                onOk: () => {
                    reqEditOpportunityStatus({id: row.id, status: '需求终止'}).then((res) => {
                        if (res.data.code === 1) {
                            this.$Message.success('终止商机成功');
                        } else {
                            this.$Message.error("终止商机失败");
                        }
                        this.getOpportunityList();
                    }).catch(() => {
                        this.$Message.error("终止商机失败");
                    })
                },
            })
        },
        handleDel(row, index) {
            this.$Modal.confirm({
                title: '删除商机',
                content: '确定删除：'+ row.customerName +' 商机，此操作不可逆',
                width: '320',
                onOk: () => {
                    reqDelOppOpportunity({id: row.id}).then((res)=>{
                        if (res.data.code === 1) {
                            this.$Message.success('删除商机成功');
                        } else {
                            this.$Message.error("删除商机失败");
                        }
                        this.getOpportunityList();
                    }).catch(()=>{
                        this.$Message.error("删除商机失败");
                    })
                },
            })
        },
        getTypeList() {
            reqGetDictionaryListByCode({code: 'opportunity_type'}).then((res) => {
                this.typeList = res.data.data
            }).catch(() => {
                this.typeList = []
            })
        },
        getSubordinateIds(){
            reqGetSubordinateIds().then((res) => {
                this.subordinate = res.data || [];
            }).catch(() => {
                this.subordinate = [];
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getOpportunityList()
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getOpportunityList()
        },
        getUserList(){
            reqGetUserList().then((res) => {
                this.createArray = res.data;
            }).catch(() => {
                this.createArray = [];
            })
        },
        doSearching() {
            this.pageNum = 1;
            this.getOpportunityList()
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId
        permission.getPermissionButton(permissionId, this.permissionButton)
        this.userName = sessionStorage.getItem("un")
        this.getOpportunityList()
        this.getTypeList()
        this.getSubordinateIds()
        this.getUserList()
    }
}
</script>


